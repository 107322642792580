import React, {useEffect, useRef, useState} from 'react';
import {Button, Collapse, Modal, Select, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {
    createClient,
    createClients, getAdminAgentClientList, getAdminSingleAgent, getAgentClientList,
    getSingleAgent,
    sendPdfClient,
    updateBrokerClient
} from "../../../redux/services";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import SendPdfForm from "../../../Components/Pages/Dashboard/SendPdfForm";
import ClientForm from "../../../Components/Pages/Dashboard/clientForm";
import {priceTableDataIndex} from "../../../Components/staticObjects";
import "../../../Components/Pages/Dashboard/dashboardStyle.scss";
import {titleize} from "../../../utils/formatter";

function AgentViewMobile() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { agentClient } = useSelector((state) => state.client);
    const { singleList } = useSelector((state) => state.agent);
    const { data } = useSelector((state) => state?.user);
    const [fileUrl, setFileUrl] = useState("");
    const [isPdf, setIsPdf] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isContractModalVisible, setIsContractModalVisible] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showManipluationForm, setShowManipluationForm] = useState(false); // Control visibility of PDFForm
    const profilePhoto = singleList?.record?.profile_photo || "https://images.unsplash.com/photo-1511367461989-f85a21fda167?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDEwfHxpbmNpZGVudCUyMGljb258ZW58MHx8fHwxNjAwNzU5NDgz&ixlib=rb-1.2.1&q=80&w=400";
    const navigate = useNavigate();
    const { list } = useSelector((state) => state.client);
    const { Option } = Select;
    const formRef = useRef(null); // Ref to handle form submission manually
    const { template } = useSelector((state) => state.client);
// #updated
    const [client, setClient] = useState(null); // null for new, object for edit
    const [agent, setAgent] = useState(null); // null for new, object for edit

    const [iseditModalVisible, setIseditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const [itemToDelete, setItemToDelete] = useState(null);
    const [isPDFModalVisible, setIsPDFModalVisible] = useState(false);
    const [selectedPDF, setSelectedPDF] = useState(null);

    const [showTermsModal, setShowTermsModal] = useState(false); // Modal for terms and conditions
    const [pdftData, setPdftData] = useState(null); // New state for re-render

    const formatData = (data) => {
        const dateString = data;
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        return moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
    }

    const handleOkClick = () => {
        let filtredPdftData = Object.fromEntries(Object.entries(pdftData).filter(([_, v]) => v !== undefined));
        const main = ['residential_buyer_tenant_agreement', 'residential_buyer_tenant_short_agreement', 'information_brokerage_agreement']
        let _pdf = selectedPDF?.template_name
        main.forEach((d)=>{
            if(selectedPDF?.template_name.includes(d)){
                _pdf = d
            }
        })
        dispatch(sendPdfClient({
                id: selectedItemId,
                pdf_name: _pdf,
                ...filtredPdftData
            }

        ))

        setShowTermsModal(false); // Close terms modal
        setSelectedPDF(null);
        setIsContractModalVisible(false);
        setPdftData(null);

        // setShowTermsModal(true);
        setSelectedPDF(null);
        setIsContractModalVisible(false);
        setShowManipluationForm(false);
    };

    const handlePdfManipulation = (pdfdata) => {
        setPdftData(pdfdata)
        setShowTermsModal(true);
        setIsContractModalVisible(false);
        setShowManipluationForm(false);
    };

    const handlePdfSend = () => {
        if (selectedPDF) {
            setShowManipluationForm(true);
        }
    };

    useEffect(() => {
        if (data.role === "broker") {
            dispatch(
                getSingleAgent({
                    id: id,
                })
            );
            dispatch(getAgentClientList({
                id: id
            }))
        } else if (data.role === "admin") {
            dispatch(
                getAdminSingleAgent({
                    id: id
                })
            );
            dispatch(getAdminAgentClientList({
                id: id
            }))
        }
    }, []);

    useEffect(() => {
        if (data.role === "broker") {
            dispatch(
                getSingleAgent({
                    id: id,
                })
            );
            dispatch(getAgentClientList({
                id: id
            }))
        } else if (data.role === "admin") {
            dispatch(
                getAdminSingleAgent({
                    id: id
                })
            );
            dispatch(getAdminAgentClientList({
                id: id
            }))
        }
    }, []);

    const handlePDFSelect = (value) => {
        let document = template.find((t)=> t.template_name === value)
        if (document) {
            let start = document.metadata.start_date ? formatData(document.metadata.start_date) : '';
            let end = document.metadata.end_date ? formatData(document.metadata.end_date) : '';
            const data = {...document.metadata, start_date: start, end_date: end}
            data.template_name = document.template_name
            setSelectedPDF(data);
        } else {
            let defaultDoc = {template_name: value}
            setSelectedPDF(defaultDoc);
        }
        if (selectedPDF) {
            setShowManipluationForm(true);
            setIsContractModalVisible(true)
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIseditModalVisible(false);
        setClient(null);
        setAgent(null);
        setIsPDFModalVisible(false);
        setIsDeleteModalVisible(false);
        setItemToDelete(null);
        setIsContractModalVisible(false);
        setShowManipluationForm(false)
    };

    const handleEditForm = (clientdata) => {
        dispatch(updateBrokerClient(clientdata));
        setIseditModalVisible(false);
    };

    const handleNewClient = (clientdata) => {
        if(data.role === 'broker') {
            dispatch(createClients(clientdata));
        } else if (data.role === 'agent'){
            dispatch(createClient(clientdata));
        }
        setClient(null); // No client data for a new entry
        setIseditModalVisible(false);
    };

    const handleNameClick = (client_id) => {
        navigate(`/agents/${id}/contacts/${client_id}`)
    };

    const handleCancelClick = () => {
        setShowTermsModal(false); // Close terms modal if they decline
    };

    const { role, name, expand } = priceTableDataIndex;
    const priceTbCol = [
        { dataIndex: name, title: "Name" },
        { dataIndex: role, title: "Status" },
        { dataIndex: expand, title: " " },
    ];



    const dataList = (clientdata) => {
        if (clientdata && Object.keys(clientdata).length > 0) {
            return clientdata.map((item) => ({
                ...item,
                name: (
                    <span style={{ color: '#707070'}}>
                        {item?.name == null ? 'Unprovided' : item?.name}
                    </span>
                ),
                expand: (
                    <a
                        href={`#`}
                        style={{ textDecoration: 'none'}}
                        onClick={(e) => {
                            e.preventDefault();
                            handleNameClick(item.id);
                        }}
                    >
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.66028L8.49999 7.98549" stroke="#2D4B6D" stroke-width="2" stroke-linecap="round"/>
                            <path d="M8.5 7.98547L1.00001 14.3107" stroke="#2D4B6D" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </a>

                ),
                role: (
                    <Button
                        style={{
                            backgroundColor: item.role === 'contact' ? '#B9A143' : '#4CAF50',
                            color: "white", // White text
                            padding: "7px 20px", // Adjusted padding to make the button a bit smaller
                            border: "none", // No border
                            borderRadius: "28px", // Rounded corners
                            cursor: "pointer", // Pointer on hover
                            margin: "5px", // Adjust margin if needed
                            width: "110px", // Set a fixed width
                            display: "flex", // Use flexbox for centering the text
                            justifyContent: "center", // Horizontally center text
                            alignItems: "center", // Vertically center text
                            textAlign: "center" // Ensure text is centered in case of longer text
                        }}
                    >
                        {titleize(item.role)}
                    </Button>
                ),
            }));
        } else {
            return [];
        }
    };

    return (
        <>
            <div className="client-outer">
                <div className="client-profile">
                    <div className="client-img">
                        <img src={profilePhoto} alt="Banner" style={{
                            objectFit: 'cover'
                        }}/>
                    </div>
                    <div className="client-name">
                        <h3>{singleList?.record?.name ?
                            `${singleList?.record?.name}` : 'Unprovided'}</h3>
                        <p>{singleList?.record?.email}</p>
                    </div>
                </div>

                <div className="client-info">

                    <div className="info-text-outer">
                        <div className="info-text">
                            <h4>Agent Information</h4>
                        </div>
                    </div>

                    <div className="client-form">
                        <p><strong>Phone :</strong> <span>
                                                    <a
                                                        href={`sms:${singleList?.record?.phone_number}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    > {singleList?.record?.phone_number}
                                                    </a>
                                </span></p>
                        <p><strong>Email :</strong> <span>
                                                    <a
                                                        href={`mailto:${singleList?.record?.email}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {singleList?.record?.email}
                                                    </a>
                                </span></p>
                        <p><strong>Total Contacts :</strong> <span>{agentClient?.length}</span></p>
                        <p><strong>Status :</strong> <span>
                            {titleize(singleList?.record?.role)}
                        </span></p>
                        <p><strong>Broker :</strong> <span>{singleList?.record?.broker_name}</span></p>
                    </div>

                    <div className="info-text-outer" style={{ marginTop: '20px', marginBottom: '10px' }}>
                        <div className="info-text">
                            <h4>{singleList?.record?.name ?
                                `${singleList?.record?.name}` : 'Unprovided'}'s  Clients</h4>
                        </div>
                    </div>

                    <div className="tableMain" style={{ marginBottom: '30px'}}>
                        <Table
                            rowKey={(obj) => obj.keyId}
                            columns={priceTbCol}
                            dataSource={dataList(agentClient)}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        handleNameClick(record.id);
                                    },
                                };
                            }}
                            pagination={false}
                            className="priceTable"
                            style={{ boxShadow: "none" }}
                        />
                    </div>

                </div>
            </div>
            <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={800} // Adjust width as necessary
            >
                {isPdf ? (
                    <iframe
                        src={fileUrl}
                        style={{ width: '100%', height: '800px' }} // Adjust height as needed
                        title="PDF Preview"
                    />
                ) : (
                    <img
                        src={fileUrl}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }} // Responsive image
                    />

                )}
            </Modal>
            <Modal
                title={showManipluationForm ? `Form for ${selectedPDF?.title ? selectedPDF?.title  : selectedPDF?.template_name}  ` : "Select a PDF"}
                visible={isContractModalVisible}
                onOk={handlePdfSend}
                className="add-new-agent"
                footer={
                    showManipluationForm
                        ? [
                            <Button key="canel" onClick={handleCancel}>
                                cancel
                            </Button>,
                        ]
                        : [

                            <Button key="ok" onClick={handlePdfSend}>
                                OK
                            </Button>,
                        ]
                }
                onCancel={handleCancel}
                okButtonProps={{ disabled: !selectedPDF && !showManipluationForm }} // Disable OK if no selection and form not showing
            >

                {!showManipluationForm ? (
                    <Select
                        style={{ width: "100%" }}
                        placeholder="Select a PDF"
                        onChange={handlePDFSelect}
                    >
                        <Option value="residential_buyer_tenant_agreement">
                            Residential Buyer/Tenat Representative - Long
                        </Option>
                        <Option value="residential_buyer_tenant_short_agreement">
                            Residential Buyer/Tenat Representative - Short
                        </Option>
                        <Option value="information_brokerage_agreement">
                            Information about Brokerage Services
                        </Option>
                        { (template.map((option) => (
                            <Option key={option.id} value={option.template_name}>
                                { option?.title ?  option.title  : option.template_name}
                            </Option>
                        )))}
                    </Select>
                ) : (
                    // Show the form once the PDF is selected
                    <SendPdfForm
                        formRef={formRef} // Pass formRef to handle manual submission
                        onSubmit={handlePdfManipulation}
                        selectedPDF={selectedPDF}
                    />
                )}
            </Modal>
            <div>
                {showTermsModal && (
                    <div style={styles.overlay}>
                        <div style={styles.modal}>
                            <h2 style={styles.title}>Participation Acknowledgment</h2>
                            <div style={styles.content}>
                                <p>
                                    By clicking 'I accept,' you confirm that you have reviewed and agree InkAgent Terms and
                                    Conditions, including any supplemental terms related to electronic signature services.
                                    Additionally, you consent to having the documents involved in this electronic signature
                                    process provided in digital format and authorize the use of your electronic signature and
                                    initials for signing any documents as part of this process.
                                </p>
                            </div>
                            <div style={styles.buttons}>
                                <button onClick={handleOkClick} style={styles.okButton}>I Accept</button>
                                <button onClick={handleCancelClick} style={styles.cancelButton}>I do not Accept</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Your contract modal */}
                {isContractModalVisible && (
                    <div>
                        {/* Your contract modal content */}
                    </div>
                )}
            </div>

            <Modal
                title={client ? "Edit Contact" : "Add New Contact"}
                visible={iseditModalVisible}
                onCancel={handleCancel}
                footer={null} // Disable default footer
                className="add-new-agent"
            >
                {client ? (
                    <ClientForm
                        client={singleList.data}
                        extraData={true} // Pass the new variable here
                        onSave={handleEditForm}
                        onCancel={handleCancel}
                    />
                ) : (
                    <ClientForm
                        client={client}
                        extraData={true} // Pass the new variable here
                        onSave={handleNewClient}
                        onCancel={handleCancel}
                    />
                )}
            </Modal>

        </>
    );
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // On top of everything
    },
    modal: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '500px',
        width: '100%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    title: {
        fontSize: '1.5rem',
        marginBottom: '15px',
    },
    content: {
        marginBottom: '20px',
        fontSize: '1rem',
        color: '#555',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    okButton: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    cancelButton: {
        padding: '10px 20px',
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    }
};

export default AgentViewMobile;