import { createSlice } from "@reduxjs/toolkit";
import {
    getClientList,
    getAgentClientList,
    getSingleClientList,
    getpdfListing,
    getClientpdfListing,
    getSingleClientListing,
    getAgentClientpdf,
    getTemplate,
    getAgentTemplate,
    getAdminClientList,
    getAdminAgentClientList,
    getpdfAdminListing,
    updateBrokerClient,
    getSingleAgentClient, getpdfClientCount
} from "../../services";

const clientListSlice = createSlice({
    name: "client",
    initialState: {
        error: false,
        loading: false,
        list: [],
        clientList: [],
        agentCount: [],
        singlePost: {},
        agentClient: {},
        total: 0,
        template: [],
        agentTemplate: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateBrokerClient.pending, (state) => {
                state.error = false;
                state.list = [];
            })
            .addCase(updateBrokerClient.fulfilled, (state, action) => {
                state.singlePost.data = action?.payload?.singlePost;
            })
            .addCase(updateBrokerClient.rejected, (state) => {
                state.list = [];
                state.error = true;
            })
            .addCase(getClientList.pending, (state) => {
                state.error = false;
                state.list = [];
            })
            .addCase(getClientList.fulfilled, (state, action) => {
                state.list = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getClientList.rejected, (state) => {
                state.list = [];
                state.error = true;
            })
            .addCase(getSingleClientList.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(getSingleClientList.fulfilled, (state, action) => {
                state.singlePost = action?.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getSingleClientList.rejected, (state) => {
                state.singlePost = {};
                state.error = true;
                state.loading = false;
            })
            .addCase(getSingleAgentClient.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(getSingleAgentClient.fulfilled, (state, action) => {
                state.singlePost = action?.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getSingleAgentClient.rejected, (state) => {
                state.singlePost = {};
                state.error = true;
                state.loading = false;
            })
            .addCase(getpdfListing.pending, (state) => {
                state.error = false;
                state.document = [];
            })
            .addCase(getpdfListing.fulfilled, (state, action) => {
                state.document = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getpdfListing.rejected, (state) => {
                state.document = [];
                state.error = true;
            })
            .addCase(getpdfClientCount.pending, (state) => {
                state.error = false;
                state.agentCount = [];
            })
            .addCase(getpdfClientCount.fulfilled, (state, action) => {
                state.agentCount = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getpdfClientCount.rejected, (state) => {
                state.agentCount = [];
                state.error = true;
            })
            .addCase(getpdfAdminListing.pending, (state) => {
                state.error = false;
                state.document = [];
            })
            .addCase(getpdfAdminListing.fulfilled, (state, action) => {
                state.document = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getpdfAdminListing.rejected, (state) => {
                state.document = [];
                state.error = true;
            })
            .addCase(getClientpdfListing.pending, (state) => {
                state.error = false;
                state.clientList = [];
            })
            .addCase(getClientpdfListing.fulfilled, (state, action) => {
                state.clientList = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getClientpdfListing.rejected, (state) => {
                state.clientList = [];
                state.error = true;
            })
            .addCase(getAgentClientpdf.pending, (state) => {
                state.error = false;
                state.clientList = [];
            })
            .addCase(getAgentClientpdf.fulfilled, (state, action) => {
                state.clientList = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getAgentClientpdf.rejected, (state) => {
                state.clientList = [];
                state.error = true;
            })
            .addCase(getAdminAgentClientList.pending, (state) => {
                state.error = false;
                state.agentClient = [];
            })
            .addCase(getAdminAgentClientList.fulfilled, (state, action) => {
                state.agentClient = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getAdminAgentClientList.rejected, (state) => {
                state.agentClient = [];
                state.error = true;
            })
            .addCase(getAgentClientList.pending, (state) => {
                state.error = false;
                state.agentClient = [];
            })
            .addCase(getAgentClientList.fulfilled, (state, action) => {
                state.agentClient = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getAgentClientList.rejected, (state) => {
                state.agentClient = [];
                state.error = true;
            })
            .addCase(getAdminClientList.pending, (state) => {
                state.error = false;
                state.agentClient = [];
            })
            .addCase(getAdminClientList.fulfilled, (state, action) => {
                state.agentClient = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getAdminClientList.rejected, (state) => {
                state.agentClient = [];
                state.error = true;
            })
            .addCase(getSingleClientListing.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(getSingleClientListing.fulfilled, (state, action) => {
                state.singlePost = action?.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getSingleClientListing.rejected, (state) => {
                state.singlePost = {};
                state.error = true;
                state.loading = false;
            })

            .addCase(getTemplate.pending, (state) => {
                state.error = false;
                state.template = [];
            })
            .addCase(getTemplate.fulfilled, (state, action) => {
                state.template = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getTemplate.rejected, (state) => {
                state.template = [];
                state.error = true;
            })

            // .addCase(getAgentTemplate.pending, (state) => {
            //     state.error = false;
            //     state.agentTemplate = [];
            // })
            // .addCase(getAgentTemplate.fulfilled, (state, action) => {
            //     state.agentTemplate = action?.payload?.list;
            // })
            // .addCase(getAgentTemplate.rejected, (state) => {
            //     state.agentTemplate = [];
            //     state.error = true;
            // })
            ;
    },
});

export const { } = clientListSlice.actions;
export default clientListSlice.reducer;
