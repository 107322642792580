import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { getUser } from "./user";

export const getAdminClientList = createAsyncThunk(
    "client/admin/clients",
    async (payload = {}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/admin/brokers?${buildQueryString(payload)}`;
            let res = await API.get(config.barong)(url, payload);
            dispatch(stopLoading());
            return {
                list: res,
                total: res?.headers?.total,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getAdminAgentClientList = createAsyncThunk(
    "admin/agent/clients",
    async (payload = {}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/admin/brokers/parent/contacts?${buildQueryString(payload)}`;
            let res = await API.get(config.barong)(url, payload);
            dispatch(stopLoading());
            return {
                list: res,
                total: res?.headers?.total,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getAgentClientList = createAsyncThunk(
    "client/agent/clients",
    async (payload = {}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/broker/agents/clients?${buildQueryString(payload)}`;
            let res = await API.get(config.barong)(url, payload);
            dispatch(stopLoading());
            return {
                list: res,
                total: res?.headers?.total,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getClientList = createAsyncThunk(
  "client/Listing",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url, payload);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getpdfAdminListing = createAsyncThunk(
    "admin/pdfListing",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `admin/documents/shared/docs`;
            let res = await API.get(config.barong)(url);
            dispatch(stopLoading());
            return {
                list: res,
                total: res?.headers?.total,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);


export const getpdfListing = createAsyncThunk(
  "client/pdfListing",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/shared/pdf?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getClientpdfListing = createAsyncThunk(
  "client/pdfListings",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/shared/docs?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendDocumentCopy = createAsyncThunk(
  "client/docCopy",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/resend/docs?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
        dispatch(
            successToastSelector({
                message: "The agreement has been re-sent via email and SMS.",
            })
        );
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendDocumentCopyAgent = createAsyncThunk(
  "client/agentDocCopy",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/resend/docs?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
        dispatch(
            successToastSelector({
                message: "The agreement has been re-sent via email and SMS.",
            })
        );
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAgentClientpdf = createAsyncThunk(
  "agent/client/pdfListings",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/shared/docs?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getTemplate = createAsyncThunk(
  "client/template",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/shared/template`;
      let res = await API.get(config.barong)(url);
      
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAgentTemplate = createAsyncThunk(
  "client/template",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/shared/template`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getpdfClientCount = createAsyncThunk(
    "client/pdfListincountg",
    async (payload = {}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `client/document/agent_counts?${buildQueryString(payload)}`;
            let res = await API.get(config.barong)(url);
            dispatch(stopLoading());
            return {
                list: res,
                total: res?.headers?.total,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getpdfClientListing = createAsyncThunk(
  "client/pdfListing",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `client/document?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getpdfagentListing = createAsyncThunk(
  "client/pdfListing",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/shared/pdf?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getClientListing = createAsyncThunk(
  "client/Listing",
  async (payload= {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAdminAgentList = createAsyncThunk(
    "admin/Listing",
    async (payload= {}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/admin/brokers/agents?${buildQueryString(payload)}`;
            let res = await API.get(config.barong)(url);
            dispatch(stopLoading());
            return {
                records: res,
                total: res?.headers?.total,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getAgentList = createAsyncThunk(
  "agent/Listing",
  async (payload= {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        records: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getPlanList = createAsyncThunk(
  "plans/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/payment/plans`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        plans: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const choosePlan = createAsyncThunk(
  "plans/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/payment/plans?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: " Plan Choosen Successfully",
        })
      );
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updatePlan = createAsyncThunk(
    "plans/update",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/resource/payment/pay?${buildQueryString(payload)}`;
            let res = await API.post(config.barong)(url);
            dispatch(
                successToastSelector({
                    message: " Card Upgraded & Payment Made Successfully",
                })
            );
            dispatch(stopLoading());
            window.location.reload();
            return res;
          } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const sendResetToken = createAsyncThunk(
    "client/SendResetToken",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/resource/users/reset_token`;
            let res = await API.post(config.barong)(url, payload);
            dispatch(successToastSelector({message: "A reset link has been sent."}));
            dispatch(stopLoading());
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);


export const getAdminSingleAgent = createAsyncThunk(
    "admin/SingleList",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/admin/brokers/data?${buildQueryString(payload)}`;
            let res = await API.get(config.barong)(url);
            dispatch(stopLoading());
            return {
                record: res,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getSingleAgent = createAsyncThunk(
  "agent/SingleList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/${payload.id}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        record: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendContactUs = createAsyncThunk(
  "public/contactUs",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/public/contact-us?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(stopLoading());
        dispatch(
            successToastSelector({
                message: "Your message has been sent successfully.",
            })
        );
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const deleteSingleClient = createAsyncThunk(
    "client/singlePost",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/broker/client/${payload.id}`;
            let res = await API.delete(config.barong)(url);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: "Client Removed Successfully",
                })
            );
            dispatch(getClientList());
            return {
                data: res,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const deleteSingleAgentClient = createAsyncThunk(
    "client/singlePost",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/agent/client/${payload.id}`;
            let res = await API.delete(config.barong)(url);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: "Client Removed Successfully",
                })
            );
            dispatch(getClientList());
            return {
                data: res,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getSingleClientList = createAsyncThunk(
  "client/singlePost",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleAgentClient = createAsyncThunk(
  "agents/singlePost",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agents/client?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleClientListing = createAsyncThunk(
  "agent/singleClient",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createClient = createAsyncThunk(
  "client/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Added Successfully",
        })
      );
      dispatch(getClientListing());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createClients = createAsyncThunk(
  "client/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Added Successfully",
        })
      );
      dispatch(getClientList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createAgent = createAsyncThunk(
  "agent/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Agent Added Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const pdfmanipluate = createAsyncThunk(
  "pdf/manipluate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/client/account/upload_pdf/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Document Signed Successfully",
        })
      );
      // alert(res.document_url);
      window.open(res.document_url, "_blank")

      dispatch(stopLoading());
      dispatch(getpdfClientListing());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createTemplate = createAsyncThunk(
  "pdf/template",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/${payload.id}/share_pdf/?${buildQueryString(
        payload
      )}`;

      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Template Created Successfully",
        })
      );
      dispatch(stopLoading());
      dispatch(getTemplate());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createAgentTemplate = createAsyncThunk(
    "sendPdf/client",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `agent/client/${payload.id}/share_pdf/?${buildQueryString(
                payload
            )}`;
            let res = await API.post(config.barong)(url);
            dispatch(
                successToastSelector({
                    message: "Template Created Successfully",
                })
            );
            dispatch(getClientListing());
            dispatch(stopLoading());
            dispatch(getAgentTemplate());
            return {
              data: res,
              status: true,
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);


export const updateProfileDetails = createAsyncThunk(
  "user/updateProfile",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
        const formDataObj = {};
        formData.forEach((value, key) => {
            formDataObj[key] = value;
        });
      dispatch(startLoading());
      let url = `/resource/users/me`;
      let res = await API.put(config.barong)(url, formData, {headers: {"Content-Type": "multipart/form-data",},});
      dispatch(successToastSelector({message: "Profile Updated Successfully"}));
      dispatch(getUser());
      dispatch(stopLoading());
      return res.data;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/createUpdateProfile",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/users/profile_data`;
      let res = await API.post(config.barong)(url, formData, {headers: {"Content-Type": "multipart/form-data",},});
      dispatch(successToastSelector({message: "Profile Updated Successfully"}));
      dispatch(getUser());
      dispatch(stopLoading());
      return res.data;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateUserFreezeStatus = createAsyncThunk(
  "user/createUpdateFreezeStatus",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/users/account_status`;
      let res = await API.post(config.barong)(url, formData, {headers: {"Content-Type": "multipart/form-data",},});
      dispatch(successToastSelector({message: "Profile Updated Successfully"}));
      dispatch(getUser());
      dispatch(stopLoading());
      return res.data;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateClient = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Updated Successfully",
        })
      );
      dispatch(getClientListing());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateAgent = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Agent Updated Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateBrokerClient = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Updated Successfully",
        })
      );
      dispatch(getClientList());
      dispatch(stopLoading());
        return {
            singlePost: res,
            total: res?.headers?.total,
            status: true,
        };

    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendPdfClient = createAsyncThunk(
  "sendPdf/client",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/${payload.id}/share_pdf/?${buildQueryString(
        payload
      )}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Document Sent Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(getClientpdfListing({id: payload.id}));
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendagentPdfClient = createAsyncThunk(
  "sendPdf/client",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/${payload.id}/share_pdf/?${buildQueryString(
        payload
      )}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Document Sent Successfully",
        })
      );
      dispatch(getClientListing());
      dispatch(getAgentClientpdf({id: payload.id}));
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const switchClientBroker = createAsyncThunk(
  "switchCleint/client",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/users/switch_client`;
      await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Mode Switched",
        })
      );
      dispatch(stopLoading());
      window.location.href = '/';
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
