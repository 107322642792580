// Determine the correct base URL based on the current window location
const getBaseUrl = () => {
  const location = window.location.hostname;

  if (location.includes("localhost")) {
    return "https://app.inkagent.ai";
  } else if (location.includes("app")) {
    return "https://app.inkagent.ai";
  } else if (location.includes("www")) {
    return "https://www.inkagent.ai";
  } else {
    return "https://www.inkagent.ai";
  }
};

export const defaultConfig = {
  title: "PDF ",
  baseUrl: getBaseUrl(),
  api: {
    authUrl: `${getBaseUrl()}/api/v2/barong`,
    tradeUrl: `${getBaseUrl()}/api/v2/peatio`,
    futureUrl: `${getBaseUrl()}/api/v2/future`,
    p2pUrl: `${getBaseUrl()}/api/v2/p2p`,
    invetsUrl: `${getBaseUrl()}/api/v2/invest`,
    applogicUrl: `${getBaseUrl()}/api/v2/applogic`,
    rangerUrl: `wss://${getBaseUrl()}/api/v2/ranger`,
    jumioUrl: "https://lon.netverify.com/api",
    demoUrl: `${getBaseUrl()}`,
  },
  captcha: {
    captchaType: "recaptcha",
    siteKey: "asdsdsd",
  },
  cloudCaptcha: {
    captchaType: "turnstile",
    siteKey: "sdsdsds",
  },
  withCredentials: true,
  usdt_value: 1,
  staticCoin: "USDT",
  staticPre: 2,
};

export const Frontend = {
  config: defaultConfig,
};

window.env = window.env || defaultConfig;
Frontend.config = { ...window.env };

Frontend.config.captcha = Frontend.config.captcha || defaultConfig.captcha;
Frontend.config.cloudCaptcha =
  Frontend.config.cloudCaptcha || defaultConfig.cloudCaptcha;

export const baseUrl = () => Frontend.config.baseUrl;
export const demoUrl = () => Frontend.config.api.demoUrl;
export const applogicUrl = () => Frontend.config.api.applogicUrl;
export const authUrl = () => Frontend.config.api.authUrl;
export const tradeUrl = () => Frontend.config.api.tradeUrl;
export const futureUrl = () => Frontend.config.api.futureUrl;
export const invetsUrl = () => Frontend.config.api.invetsUrl;
export const withCredentials = () => Frontend.config.withCredentials;
export const p2pUrl = () => Frontend.config.api.p2pUrl;
export const rangerUrl = () => Frontend.config.api.rangerUrl;
export const siteKey = () => Frontend.config.captcha.siteKey;
export const captchaType = () => Frontend.config.captcha.captchaType;
export const cloudCaptchaSiteKey = () => Frontend.config.cloudCaptcha.siteKey;
export const cloudCaptchaCaptchaType = () =>
  Frontend.config.cloudCaptcha.captchaType;
export const usdtValue = () => Frontend.config.usdt_value;
export const staticObj = () => {
  return {
    staticCoin: Frontend.config.staticCoin,
    staticPre: Frontend.config.staticPre,
  };
};
